/* Top left Span */
.topLeft:after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  background-image: url('/images/tripleLinesTopLeft.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: -25px;
  left: -45px;
}

.topLeft {
  position: relative;
  display: inline-block;
}

/* Top right Span */
.topRight:before {
  content: '';
  display: block;
  height: 158px;
  width: 158px;
  background-image: url('/images/tripleLinesTopRight.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: -45px;
  right: -85px;
}

@media screen and (max-width: 1024px) {
  .topRight:before {
    height: 114.427px;
    width: 114.427px;
    top: -40px;
    right: -65px;
  }
}

.topRight {
  position: relative;
  display: inline-block;
}

/* Bottom right Span */
.bottomRight:before {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  background-image: url('/images/tripleLinesBottomRight.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  bottom: -7vw;
  right: -20vw;
}

@media screen and (max-width: 1024px) {
  .bottomRight:before {
    height: 114.427px;
    width: 114.427px;
    bottom: -12vw;
    right: -27vw;
  }
}

.bottomRight {
  position: relative;
  display: inline-block;
}

/* Images */

.topLeftImage:after {
  content: '';
  display: block;
  height: 100px;
  width: 100px;
  background-image: url('/images/tripleLinesTopLeft.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: -30px;
  left: -60px;
}

@media screen and (max-width: 1024px) {
  .topLeftImage:after {
    left: -24px;
    top: -26px;
    height: 55px;
    width: 51px;
  }
}

.topLeftImage {
  position: relative;
  display: inline-block;
}

.bottomRightImage {
  position: relative;
  display: inline-block;
}

.bottomRightImage:after {
  content: '';
  display: block;
  height: 100px;
  width: 100px;
  background-image: url('/images/tripleLinesBottomRight.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  bottom: -55px;
  right: -45px;
}

@media screen and (max-width: 1024px) {
  .bottomRightImage:after {
    bottom: -25px;
    right: -25px;
    height: 55px;
    width: 51px;
  }
}
