.singleLine:after {
  content: '';
  display: block;
  height: 100%; /* Height of the underline */
  width: 120%; /* Full width of the text */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -5px;
  bottom: -45px; /* Adjust according to your font size and style */
  background-image: url('/images/single-underline.svg');
}

.multiLine:after {
  content: '';
  display: block;
  height: 100%; /* Height of the underline */
  width: 120%; /* Full width of the text */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -5px;
  bottom: -45px; /* Adjust according to your font size and style */
  background-image: url('/images/multi-underline.svg');
}

@media screen and (max-width: 1023px) {
  .singleLine:after {
    bottom: -20px; /* Adjust according to your font size and style */
  }

  .multiLine:after {
    bottom: -20px; /* Adjust according to your font size and style */
  }
}

.svgUnderline {
  position: relative;
  display: inline-block;
}
